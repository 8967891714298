$(function () {
    function viewMoreHandler(element) {
        lazy_accordion__fetch(element);
    }
    $(".js--lazy-accordion").click(function () {
        viewMoreHandler(this);
    });

    $(".js--lazy-accordion").keypress(function (e) {
        if (e.keyCode == 32 || e.keyCode == 13) {
            viewMoreHandler(this);
        }
    });

    function lazy_accordion__fetch(element) {
        if ($(element).attr('data-fetched') !== 'true') {
            var listing = $(element).find('.js--lazy-accordion__listing');
            var spinner = $(element).find('.js--lazy-accordion__spinner');
            $(spinner).show();
            var payload = {
                id: $(element).attr('data-id'),
                type: $(element).attr('data-type')
            };
            var json = JSON.stringify(payload, null, 2);
            $.ajax({
                type: 'POST',
                url: $(element).attr('data-fetchurl'),
                data: json,
                contentType: 'application/json; charset=utf-8',
                dataType: 'html',
                success: function (response) {
                    $(listing).append(response);
                    $(spinner).hide();
                    $(element).attr('data-fetched', 'true');
                },
                failure: function (response) {
                    $(spinner).hide();
                },
                error: function (response) {
                    $(spinner).hide();
                }
            })
        }
    }
});

function lazy_accordion__showmoreless(element) {
    var itemsPerPage = 50;
    var accordion = $(element).parents('.js--lazy-accordion');
    var accordionItems = accordion.find('.js--lazy-accordion__listing');
    var showmorebutton = $(element).attr('showmore');

    if (showmorebutton !== undefined) {        
        $(accordion).find('.hidden-cont').slice(0, itemsPerPage).removeClass('hidden-cont').addClass('show-cont').show();   
        updateButtonVisibility(accordion);             
    }
    else { 
        $(accordion).find('.show-cont').removeClass('show-cont').addClass('hidden-cont').hide();
        $(accordion).find('.expand').removeClass('expand');
        updateButtonVisibility(accordion);
        accordionItems.toggle();
    }
}

function updateButtonVisibility(accordion) {
    if($(accordion).find('.hidden-cont').length==0){
        $(accordion).find("a[showmore]").hide();
    }else{
        $(accordion).find("a[showmore]").show();
    }
    if($(accordion).find('.show-cont').length==0){
        $(accordion).find("a[showless]").hide();
    }else{            
        $(accordion).find("a[showless]").show();
    }       
}